import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    StickyScroller: props => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up(props.breakpoint)]: {
            flexDirection: 'row',
        }
    }),
    StickyScroller_column: {
        flex: '1 1 auto',
    },
    StickyScroller_content: {
        position: 'relative',
    }
}))