import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './layout.css';
import { useTheme } from '@material-ui/core/styles';

// standard image: portrait at 3:4 ratio
// landscape width: portrait width x2 + gap
// height specified in props


const ImageLayout = (props) => {

    const theme = useTheme();

    const items = React.Children.toArray(props.children);
    const isMulti = items.length > 1;

    const classes = useStyles({
        ...props,
        isMulti,
        scale: {
            ...theme.components.image.scale,
            ...props.scale,
        }
    });

    return (
        <div className={'ImageLayout ' + classes.root}>
            {React.Children.toArray(props.children).map(child => {
                return React.cloneElement(child, { className: classes.item })
            })}
        </div>
    );
}

ImageLayout.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    gap: PropTypes.number,
    landscape: PropTypes.bool,
    scale: PropTypes.objectOf(PropTypes.number),
}

ImageLayout.defaultProps = {
    height: 400,
    width: 300,
    gap: 20,
    landscape: false,
    scale: {},
}

export default ImageLayout;