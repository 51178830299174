import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {

    function scaleForMedia(props) {
        const forMedia = (query, size) => ({
            [theme.breakpoints[query](size)]: {

                // add top margin only to ImageLayouts that follow another ImageLayout
                '.ImageLayout + &': {
                    marginTop: props.gap * props.scale[size],
                },

                // maintain aspect ratio for images for each breakpoint
                '& *': {
                    height: props.height * props.scale[size],
                    width: props.landscape && !props.isMulti
                        ? (props.width * props.scale[size] * 2) + (props.gap * props.scale[size])
                        : props.width * props.scale[size],
                },

                // left padding to inner images for doubled image layout
                '& > *:not(:first-child)': {
                    marginLeft: props.gap * props.scale[size],
                },
            }
        });

        return {
            ...forMedia('only', 'xs'),
            ...forMedia('only', 'sm'),
            ...forMedia('only', 'md'),
            ...forMedia('only', 'lg'),
            ...forMedia('up', 'xl'),
        }
    }

    return {
        root: props => ({
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',

            '& img': {
                objectFit: 'cover',
            },

            ...scaleForMedia(props),
        }),
    }
});