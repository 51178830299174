import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './stickyscroller.css';
import { useTheme } from '@material-ui/core/styles';
import { getClientScrollPosition } from 'components/utility/client';

const StickyScroller = (props) => {

    const theme = useTheme();

    // refs
    const containerRef = React.useRef();
    // state
    const [state, setState] = React.useState({
        mounted: false,
        active: false,
        anchor: null,
        scrollPosition: 0,
        columns: React.Children.map(props.children, (child) => ({
            ref: React.createRef(),
            offset: 0,
            offsetLimit: 0,
            child,
            childRef: function() {
                return this.ref.current.firstElementChild;
            }
        })),
    });

    const recalibrate = React.useCallback(() => {

        const activeBreakpoint = window.innerWidth >= theme.breakpoints.values[props.breakpoint];

        // cancel calibration and deactivate if below breakpoint
        if (!activeBreakpoint) {
            return setState(state => ({
                ...state,
                active: false,
            }));
        }
        setState(state => {
            const currentAnchor = {
                index: null,
                height: 0,
            };
    
            const columnsCalibrated = state.columns.map((col, i, arr) => {
                const child = col.childRef();
    
                // anchor determination logic
                const height = child.offsetHeight;
                if (i === 0 || height > currentAnchor.height) {
                    currentAnchor.index = i;
                    currentAnchor.height = height;
                }
    
                // mapping logic
                // get offsetLimit for each column by comparing column's rendered height to viewport height
                const heightDelta = window.innerHeight - child.offsetHeight;
    
                // only set negative height delta because columns that are smaller than viewport
                // will not stick to the bottom of the container
                return heightDelta < 0
                    ? { ...col, offsetLimit: heightDelta }
                    : col;
            });
    
            return {
                ...state,
                anchor: currentAnchor.index,
                columns: columnsCalibrated,
                active: activeBreakpoint,
            };
        });
    }, [theme, props.breakpoint]);

    // action handlers
    const handleScroll = React.useCallback((e) => {

        // run only when active
        if (!state.active) return;

        // call setState immediately to have access to state in function
        setState(state => {
            const nextPosition = getClientScrollPosition();
            const delta = nextPosition - state.scrollPosition;
            const deltaAbs = Math.abs(delta);
            const container = containerRef.current;
            // detect whether the container's top and bottom limits are within the viewport
            const containerRect = container.getBoundingClientRect();
            const topInView = containerRect.top >= 0;
            const bottomInView = containerRect.bottom <= window.innerHeight;
            const withinContainer = containerRect.top < 0 && containerRect.bottom > window.innerHeight;
            // get the visible portion of the container within the viewport (always less than or equal to viewport height)
            const containerVisibleHeight = (function(){
                const top = containerRect.top < 0 ? 0 : containerRect.top;
                const bottom = containerRect.bottom > window.innerHeight ? window.innerHeight : containerRect.bottom;
                return bottom - top;
            })();
    
            // clone previous columns state updates with new top offsets
            const nextColumnsState = state.columns.map(d => ({ ...d }));
    
            nextColumnsState.forEach((column, i) => {
                // skip if column is anchor
                if (i === state.anchor) return;
                // scroll direction flag (up/down)
                const isScrollingDown = delta > 0;
                // get previous column offset state to relative repositioning
                const prevOffset = column.offset;
                // get column position relative to viewport
                const columnRect = column.ref.current.firstChild.getBoundingClientRect();
                // pin container on container top/bottom limits if visible portion of container is smaller than column
                const columnShouldBePinned = containerVisibleHeight < columnRect.height;
                // detect whether column's top and bottom limits are in view
                const columnTopInView = columnRect.top >= 0;
                const columnBottomInView = columnRect.bottom <= window.innerHeight;
                // calculate max offset that the column can have
                const columnMaxOffset = containerRect.height - columnRect.height;
                // calculate the offset required to center the column in the viewport
                const centeringOffset = (window.innerHeight - columnRect.height) / 2;

                if (topInView && bottomInView) {
                    column.offset = 0;
                }
                // top in view only -- pin to top
                else if (topInView && !bottomInView && columnShouldBePinned) {
                    column.offset = 0;
                }
                // bottom in view only -- pin to bottom
                else if (bottomInView && !topInView && columnShouldBePinned) {
                    // align the column's bottom to the container's bottom via container-column height difference
                    column.offset = containerRect.height - columnRect.height;
                }
                // scrolling within the container completely -- perform relative sticky scrolling
                else if (withinContainer) {
                    // if current non-anchor column is smaller than container visible height, center it on page
                    if (columnRect.height < containerVisibleHeight) {
                        column.offset = -containerRect.top + centeringOffset;
                    } else {
                        let nextOffset;
                        // scrolling down and column's bottom limit is in view passed
                        if (isScrollingDown && columnBottomInView && !columnTopInView) {
                            // while scrolling down, the column's position values will be decreasing, so we pin the element to the top of
                            // the viewport by subtracting the the column's top limit position from the existing offset.
                            nextOffset = prevOffset + (window.innerHeight - columnRect.bottom);
                            if (nextOffset > columnMaxOffset) {
                                nextOffset = columnMaxOffset;
                            }
                        }
                        // scrolling up and column's top limit is in view/ passed
                        else if (!isScrollingDown && columnTopInView && !columnBottomInView) {
                            // while scrolling up, the column's position values will increasing, so we pin the element to the bottom of
                            // the viewport by adding the difference between the viewport height and the column's bottom limit to the existing offset.
                            nextOffset = prevOffset - columnRect.top;
                            if (nextOffset < 0) {
                                nextOffset = 0;
                            }
                        }
                        
                        // set next offset if it has a value; otherwise, the column will scroll normally
                        if (typeof nextOffset !== 'undefined') {
                            column.offset = nextOffset;
                        }
                    }
                }
            });
    
            return {
                ...state,
                scrollPosition: nextPosition,
                columns: nextColumnsState,
            }
        });
    }, [state.active, props.pageOffset]);

    const mountListeners = React.useCallback(() => {
        setState(state => {
            unmountListeners();
            // mount listeners
            window.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', recalibrate);

            return {
                ...state,
                mounted: true,
            }
        })
    }, [recalibrate, handleScroll]);

    const unmountListeners = React.useCallback(() => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', recalibrate);
    }, [recalibrate, handleScroll]);

    // on mount
    React.useEffect(() => {
        // set up variables for current viewport
        recalibrate();
        setTimeout(recalibrate, 1000);
    }, [recalibrate]);

    // mount listeners after anchor declaration
    React.useEffect(() => {
        mountListeners();
        // cleanup
        return unmountListeners;
    }, [state.anchor, mountListeners, unmountListeners]);

    // render
    const classes = useStyles(props);

    return (
        <div className={classes.StickyScroller} ref={containerRef}>
            {state.columns.map((column, i) => (
                <div
                    key={i}
                    ref={column.ref}
                    className={classes.StickyScroller_column}
                    style={props.widths && {
                        flexGrow: 0,
                        flexShrink: 0,
                        flexBasis: state.active ? props.widths[i] : 'auto',
                    }}
                >
                    <div className={classes.StickyScroller_content} style={{ top: state.active ? column.offset : 0 }}>
                        {column.child}
                    </div>
                </div>
            ))}
        </div>
    );
}

StickyScroller.propTypes = {
    children: PropTypes.node.isRequired,
    widths: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    breakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    pageOffset: PropTypes.number,
}

StickyScroller.defaultProps = {
    breakpoint: 'xs',
    pageOffset: 0,
}

export default StickyScroller;