import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from 'components/layout/main';
import SEO from 'components/seo';
import PageTitle from 'components/page/title';
import StickyScroller from 'components/stickyscroller/stickyscroller';
import StickyNode from 'react-stickynode';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import ImageLayout from 'components/image/layout';
import PropsProvider from 'components/utility/PropsProvider';
import { sortByPath } from 'components/utility/data';
import theme from 'src/components/theme';
import useStyles from 'src/components/page/CeramicsPage.css';

const Article = ({ p, className, style }) => {
    const excerpt = () => {
        switch(p) {
            case 1: return `
                Fall 2018 was my breakout collection, the first full collection that I sold online and brick & mortar.
                This collection was the culmination of years of work and tireless nights on the ceramics wheel. This
                series started to develop in Spring 2018 when I got my first taste for creating an entire concept,
                mood, and feel of the collection.
            `;
            case 2: return `
                The breakthrough piece was my Black Beach Mug. This was a matte black glazed mug with a black clay body.
                I knew I wanted to use black clay and depart from the classic gray and red-bodied clays that I had
                previously used. Once working with this new clay, I was enamored with the possibilities and excitement
                of being one of the few potters using it at the time.
            `;
            case 3: return `
                Around the same time, I had the opportunity to go to Iceland. I was deeply inspired by the beauty and
                colors of the landscape. During my trip, I photographed my new concept mug on the black beaches, which
                set the tone for the collection. 
            `;
            case 4: return `
                Over the summer of 2018, I continued to develop glazes that would work well with the black clay body.
                I happened to stumble upon a combination when testing glazes for a collaboration with a local coffee
                shop. The result is the speckled gray glaze you see throughout the Fall collection.
            `;
            case 5: return `
                I fell in love with the unique color variants, especially how the glaze breaks into a warm, defining
                brown at the rims. I launched the Fall 2018 Collection in two drops, first with a collection of vases
                followed by a full dinnerware line.
            `;
            case 6: return `
                Having taken a break away from ceramics, it has been crucial for me to reflect on that time. I often
                think about how all the pieces are now in people's homes and a part of their daily routine.
            `;
            case 7: return `
                It's the feeling of leaving a lasting footprint with your work that is the most humbling reward.
                It has made me passionate about creating high-quality ceramics that are modern, clean, and at the end
                of the day, simple.
            `;
            default: return '';
        }
    }
    return (
        <p className={className} style={style}>
            {excerpt().trim()}
        </p>
    )
}



const CeramicsPage = (props) => {
    
    const images = props.data.allImages.nodes.sort(sortByPath);
    const IwImage = ({index}) => <Img fluid={images[index].childImageSharp.fluid} />;

    const InsetExcerpt = ({ p }) => (
        <Hidden mdUp>
            <Article p={p} />
        </Hidden>
    )

    const classes = useStyles();

    return (
        <Layout containerSize="lg">
            <SEO title="Ceramics" />
            <PageTitle navOffset>
                <h1>Fall 2018 Collection</h1>
            </PageTitle>
            <Box id="ceramics-page-columns" mt={6} pb={4} display="flex" flexDirection="row" alignItems="stretch" position="relative">
                {/* <StickyScroller breakpoint="md" widths={['40%', '60%']} pageOffset={theme.components.navbar.height}> */}
                
                <Hidden smDown>
                    <div className={classes.CeramicsTextColumn}>
                        <StickyNode top="nav-root" bottomBoundary="#ceramics-page-columns" innerClass={classes.CeramicsColumnContent}>
                            {Array(7).fill().map((_, i) => (
                                <Article key={i+1} p={i+1} />
                            ))}
                        </StickyNode>
                    </div>
                </Hidden>
                <div className={classes.CeramicsImageColumn}>
                    <div className={classes.CeramicsColumnContent}>

                        <PropsProvider
                            targets={['ImageLayout']}
                            inherit={{
                                gap: 40,
                                scale: { sm: .8, md: .8, lg: .9 }
                            }}
                        >
                            <ImageLayout>
                                <IwImage index={0} />
                            </ImageLayout>

                            <InsetExcerpt p={1} />

                            <ImageLayout landscape>
                                <IwImage index={1} />
                            </ImageLayout>
                            <ImageLayout>
                                <IwImage index={2} />
                                <IwImage index={3} />
                            </ImageLayout>
                            <ImageLayout>
                                <IwImage index={4} />
                                <IwImage index={5} />
                            </ImageLayout>

                            <InsetExcerpt p={2} />

                            <ImageLayout landscape>
                                <IwImage index={6} />
                            </ImageLayout>

                            <InsetExcerpt p={3} />

                            <ImageLayout landscape>
                                <IwImage index={7} />
                            </ImageLayout>
                            <ImageLayout>
                                <IwImage index={8} />
                            </ImageLayout>
                            <ImageLayout>
                                <IwImage index={9} />
                            </ImageLayout>
                            <ImageLayout landscape>
                                <IwImage index={10} />
                            </ImageLayout>

                            <InsetExcerpt p={4} />

                            <ImageLayout>
                                <IwImage index={11} />
                                <IwImage index={12} />
                            </ImageLayout>
                            <ImageLayout landscape>
                                <IwImage index={13} />
                            </ImageLayout>
                            <ImageLayout>
                                <IwImage index={14} />
                                <IwImage index={15} />
                            </ImageLayout>
                            <ImageLayout>
                                <IwImage index={16} />
                                <IwImage index={17} />
                            </ImageLayout>

                            <InsetExcerpt p={5} />

                            <ImageLayout>
                                <IwImage index={18} />
                            </ImageLayout>
                            <ImageLayout>
                                <IwImage index={19} />
                            </ImageLayout>

                            <InsetExcerpt p={6} />

                            <ImageLayout landscape>
                                <IwImage index={20} />
                            </ImageLayout>

                            <InsetExcerpt p={7} />

                            <ImageLayout landscape>
                                <IwImage index={21} />
                            </ImageLayout>
                        </PropsProvider>
                    </div>
                </div>
            </Box>
        </Layout>
    );
}

export const query = graphql`
    query {
        allImages: allFile(filter: {relativePath: { glob: "ceramics/works/*.jpg" }}) {
          nodes {
            publicURL
            relativePath
            childImageSharp {
                fluid(maxHeight: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
    }
`;

export default CeramicsPage;