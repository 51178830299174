import { useEffect } from 'react';

export const getDocumentElement = () => {
    if (!document) return null;
    return document.documentElement || document.body;
}

export const getClientScrollPosition = () => {
    if (!document) return 0;
    return document.documentElement.scrollTop || document.body.scrollTop;
}

export const useScript = (url, htmlScript) => {
  useEffect(() => {
    const script = document.createElement('script');

    if (url) script.src = url;
    if (htmlScript) script.innerHTML = htmlScript;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url, htmlScript]);
};
