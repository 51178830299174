import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    CeramicsTextColumn: {
        position: 'relative',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '40%',
        },
    },
    CeramicsImageColumn: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '60%',
        },
    },
    CeramicsColumnContent: {
        padding: theme.spacing(2),
    }
}))