import React from 'react';
import PropTypes from 'prop-types';

// Provides all props (except children) to all children

const PropsProvider = (props) => {

    const { children, targets, inherit } = props;

    return (
        <>
            {React.Children.map(children, (child) => {
                if (!targets || targets.includes(child.type.displayName)) {
                    return React.cloneElement(child, inherit);
                }
                return child;
            })}
        </>
    )
}

PropsProvider.propTypes = {
    children: PropTypes.node.isRequired,
    inherit: PropTypes.object,
    targets: PropTypes.arrayOf(PropTypes.string),
}

PropsProvider.defaultProps = {
    inherit: {},
}

export default PropsProvider;